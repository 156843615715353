import 'whatwg-fetch';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import ReactGA from 'react-ga4';
import { HelmetProvider } from 'react-helmet-async';

// A modern alternative to CSS resets
import 'normalize.css';
// Global css setting
import './global.css';

import Router from 'layouts/Router';

import { initi18next } from 'util/i18n';

import configureStore from './store';
import history from './store/history';
import routes from './routes';

ReactGA.initialize('G-E5J9GC2R9Z');
ReactGA.send('pageview');

const store = configureStore({});
const i18n = initi18next(store);

const renderApp = () => {
	if (/Line/.test(navigator.userAgent)) {
		location.href = `${location.href}${
			location.search ? '&openExternalBrowser=1' : '?openExternalBrowser=1'
		}`;
	}

	const rootElement = document.getElementById('content');

	if (rootElement?.hasChildNodes()) {
		ReactDOM.hydrate(
			<HelmetProvider>
				<I18nextProvider i18n={i18n}>
					<Provider store={store}>
						<Router history={history} routes={routes} store={store} />
					</Provider>
				</I18nextProvider>
			</HelmetProvider>,
			rootElement,
		);
	} else {
		ReactDOM.render(
			<HelmetProvider>
				<I18nextProvider i18n={i18n}>
					<Provider store={store}>
						<Router history={history} routes={routes} store={store} />
					</Provider>
				</I18nextProvider>
			</HelmetProvider>,
			rootElement,
		);
	}
};

// force browser reload when using browser back button to the app
window.addEventListener('pageshow', event => {
	const historyTraversal =
		event.persisted ||
		(typeof window.performance !== 'undefined' && window.performance.navigation.type === 2);

	if (historyTraversal) {
		window.location.reload();
	} else {
		renderApp();
	}
});

// 阻擋網站右鍵選單事件
document.addEventListener('contextmenu', event => event.preventDefault(), true);
